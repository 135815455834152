.App {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
}

.img-fill {
  position: fixed;
  top: 0;
  display: block;
  max-width: 100%;
  max-height: 100%;
}

.img-mapper-map {
  cursor: pointer;
}

.spinner-border {
  color: #803cc9;
  --bs-spinner-width: 4rem;
  --bs-spinner-height: 4rem;
  --bs-spinner-border-width: 0.5rem;
}